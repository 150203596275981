#deckgl-wrapper {
    position: absolute;
    z-index: 0;
    left: 12px;
    top: 0px;
    width: 100%;
    height: 100%;
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    cursor: grab;
}
.map {
    left: 15px;
    top: 0px;
    width: 100%;
    position: absolute;
    height: 100%;
}
.leaflet-control-attribution svg {
    width: 0
}